import React from 'react'
import Navbar from './Navbar'
import { useState, useEffect } from 'react'
import { supabase } from '../supa'
import Swal from "sweetalert2";

function Signup() {

    const [user, setUser] = useState(null)
    const [password, setPassword] = useState(null)
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        console.log()
    }, [])
    async function RedirectLogin() {
        setLoading(true)
        const { data, error } = await supabase.auth.signUp({
            email: user,
            password: password
        })

        if (error) {
            Swal.fire({
                title: error.message,
                icon: "error",
                confirmButtonText: "ok",
            });
            setLoading(false)
            return
        }

        if (data) {
            if (data?.user?.identities?.length === 0) {
                Swal.fire({
                    title: "User already exists!",
                    icon: "error",
                    confirmButtonText: "ok",
                });
                return;
            }
            else {
                await Swal.fire({
                    title: "User created, Please check your email to verify your account!",
                    icon: "success",
                    confirmButtonText: "ok",
                });

                window.location.replace('/login')
                return;
            }
        }
        setLoading(false)
        return
    }

    return (
        <div class='w-full h-screen bg-gradient-to-br from-[#fcc502] to-[#03296b] '>
            {loading && (<div className="flex items-center justify-center h-screen transition-all ">
                <div className="relative">
                    <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
                    <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
                    </div>
                </div>
            </div>)}
            <Navbar check={1} />
            <div class='w-full h-full flex justify-center items-center p-6'>
                <div class='lg:w-[37%] md:w-[50%] sm:w-[85%] items-center flex flex-col space-y-14 bg-white rounded-xl py-10 px-14 mt-20'>
                    <div class='flex flex-col space-y-5 items-center'>
                        <text class='text-4xl font-font1 font-bold text-center'>Create an account</text>
                        <text class='text-center text-black/50'>Bem-vindo ao Kassoma IA Speech Analyzer! Vamos configurar você!</text>
                    </div>
                    <div class='w-full flex flex-col space-y-5'>
                        <div class='w-full flex flex-col'>
                            <text class='text-black/70'>Email</text>
                            <input onChange={(e) => setUser(e.target.value)} class='w-full pt-3 border-b-[2px] outline-none pb-1' placeholder='abc@mail.com'></input>
                        </div>
                        <div class='w-full flex flex-col'>
                            <text class='text-black/70'>Password</text>
                            <input onChange={(e) => setPassword(e.target.value)} class='w-full pt-3 border-b-[2px] outline-none pb-1' placeholder='........' type='password'></input>
                        </div>
                    </div>

                    <div class='w-full space-y-5 flex flex-col'>
                        <button class='w-full py-3 rounded-xl font-font2 text-lg bg-[#03296b] text-white' onClick={RedirectLogin}>Create an account</button>
                        <div class='flex items-center space-x-5'>
                            <hr class='w-1/2 border-[1px]' />
                            <text class='text-black/50'>ou</text>
                            <hr class='w-1/2 border-[1px]' />
                        </div>
                        <div class='flex w-full justify-center space-x-2'>
                            <text>Already have an account? </text>
                            <button class='text-[#03296b] underline' onClick={(e) => window.location.replace('/login')}>Sign in</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup