import React, { useState, useEffect } from 'react'
import { BsVectorPen, BsBookHalf } from 'react-icons/bs'
import { RiVoiceprintFill } from 'react-icons/ri'
import { BiSolidSend, BiMenu } from 'react-icons/bi'
import UserChat from './UserChat'
import BotChat from './BotChat'
import Sidebar2 from './Sidebar2'
import axios from 'axios'
import { supabase, checkIfLogin } from '../supa'
import Swal from "sweetalert2";

function Dashboard() {
    const [plan, setPlan] = useState('')
    useEffect(() => {
        getPlan()

    }, [])

    const getPlan = async () => {
        const jwt = `${checkIfLogin()}`
        await axios
            .get(`${process.env.REACT_APP_BACKEND}/stripe/getCurrentPlan/`, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            })
            .then(function (response) {
                if (response.data.length <= 0) {
                    setPlan('Free')
                }
                else if (response.data === `${process.env.REACT_APP_PLAN1}`) {
                    setPlan('Standard')
                }
                else if (response.data === `${process.env.REACT_APP_PLAN2}`) {
                    setPlan('Premium')
                }
            })
            .catch(function (error) {
                console.log(`Error : ${error}}`);
            });
    }

    const [Gen, setGen] = useState(false)
    const [FirstMessage, SetFirstMessage] = useState(true)
    const [Input, setInput] = useState('')
    const [TotalMessageChat, setTotalMessageChat] = useState([{
        role: 'system', content: 'You are a \
    virtual assistant you speak in Portugese, Your name is Kassoma. You are a personal English language trainer. Your help portugese people learn english.\
    You only reply in portugese, unless users requires you to reply in English.' }])
    const [TotalMessageLesson, setTotalMessageLesson] = useState([{
        role: 'system', content: 'You are a \
    virtual assistant you speak in Portugese, Your name is Kassoma. You are a personal English language trainer. Your help portugese people learn english.\
    You will keep the conversation going by asking questions that helps in learning english. \
    At each response you will tell the user if their answer was corrent or not, and give a brief description of they could be better.\
    User will tell you about the level of english the know and you will act acordingly. You only reply in portugese, unless users requires you to reply in English.' },
    {
        role: "assistant",
        content: "Olá, sou Kassoma, sua professora particular de inglês. Que nível de inglês você conhece?",
    }])
    const [isLesson, setIsLesson] = useState(false)

    const [SidebarCheck, setSidebarCheck] = useState(false)
    useEffect(() => {
        document
            .getElementById("ScrollToMe")
            .scrollIntoView({ block: "end", behavior: "smooth" });
    }, [isLesson])
    function RedirectLogin() {
        window.location.replace('/login')
    }
    function RedirectPayment() {
        window.location.replace('/payment')
    }




    function HandleInput(e) {
        setInput(e.target.value)
    }

    async function StartChat() {
        if (Input.length > 0) {


            if (checkIfLogin()) {
                const jwt = `Bearer ${checkIfLogin()}`
                if (!isLesson) {

                    let gptArray = TotalMessageChat
                    gptArray.push({
                        role: "user",
                        content: Input,
                    })
                    setInput('')

                    console.log(gptArray)
                    document
                        .getElementById("ScrollToMe")
                        .scrollIntoView({ block: "end", behavior: "smooth" });

                    const url = `${process.env.REACT_APP_BACKEND}/chat`;
                    setGen(true)
                    try {
                        const stream = await fetch(`${url}`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: jwt,
                            },
                            body: JSON.stringify({ prompt: gptArray }),
                        });


                        const reader = stream.body.getReader();
                        const decoder = new TextDecoder("utf-8");
                        let temp = ""; // we are getting data from gpt here
                        while (true) {
                            const { done, value } = await reader.read();
                            if (done) {
                                break;
                            }
                            temp += decoder.decode(value);
                            setTotalMessageChat([
                                ...TotalMessageChat,
                                {
                                    role: "assistant",
                                    content: temp,
                                }
                            ]);
                            document
                                .getElementById("ScrollToMe")
                                .scrollIntoView({ block: "end", behavior: "smooth" });
                        }
                    } catch (err) {
                        Swal.fire({
                            title: "Limit Exceeded, Please pay to continue !",
                            icon: "error",
                            confirmButtonText: "ok",
                        });
                        setGen(false)
                    }
                    setGen(false)
                }
                else {
                    var gptArray = TotalMessageLesson
                    gptArray.push({
                        role: "user",
                        content: Input,
                    })
                    setInput('')

                    console.log(gptArray)
                    document
                        .getElementById("ScrollToMe")
                        .scrollIntoView({ block: "end", behavior: "smooth" });

                    const url = `${process.env.REACT_APP_BACKEND}/chat`;
                    setGen(true)
                    try {
                        if (FirstMessage) {
                            var stream = await fetch(`${url}`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: jwt,
                                },
                                body: JSON.stringify({ prompt: gptArray, lesson: true }),
                            });
                            SetFirstMessage(false)
                        }
                        else {
                            var stream = await fetch(`${url}`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: jwt,
                                },
                                body: JSON.stringify({ prompt: gptArray }),
                            });
                        }


                        const reader = stream.body.getReader();
                        const decoder = new TextDecoder("utf-8");
                        let temp = ""; // we are getting data from gpt here
                        while (true) {
                            const { done, value } = await reader.read();
                            if (done) {
                                break;
                            }
                            temp += decoder.decode(value);
                            setTotalMessageLesson([
                                ...TotalMessageLesson,
                                {
                                    role: "assistant",
                                    content: temp,
                                }
                            ]);
                            document
                                .getElementById("ScrollToMe")
                                .scrollIntoView({ block: "end", behavior: "smooth" });
                        }
                    } catch (err) {
                        Swal.fire({
                            title: "Limit Exceeded, Please pay to continue !",
                            icon: "error",
                            confirmButtonText: "ok",
                        });
                        setGen(false)
                    }
                    setGen(false)
                }
                setGen(false)
            }

        }
        else {
            Swal.fire({
                title: "Login Expired, Please login again !",
                icon: "error",
                confirmButtonText: "ok",
            }).then(() => {
                RedirectLogin()
            });
        }




    }

    function HandleSideBar() {
        setSidebarCheck(!SidebarCheck)
    }

    return (
        <div class='w-full h-screen flex'>

            {
                SidebarCheck == true ?
                    <Sidebar2 />
                    :
                    <></>
            }

            <div class='lg:w-[19%] md:w-[24%] h-full bg-[#03296b]/5 backdrop-blur-md md:flex hidden flex-col py-10 lg:px-4 md:px-4 items-start justify-between'>
                <div className="flex flex-col space-y-5">
                    <text class='text-3xl font-font1 font-bold '>Kassoma IA</text>
                    <text class={`text font-font1 font-bold px-2 shrink ${plan === "Free" ? 'bg-red-500 rounded-xl' : 'bg-green-500 rounded-xl'}`}>{plan ? `${plan} Plan` : ''}</text>

                </div>

                <div class='w-full flex flex-col space-y-1'>

                    <button
                        onClick={() => setIsLesson(true)}
                        class={`flex transition-all items-center font-font2 space-x-5 py-3 px-3 rounded-full hover:bg-[#f1ce4d] ${isLesson ? "bg-[#fcc502]" : ""}`}>
                        <BsVectorPen size='1.5rem' color='#03296b' />
                        <text class='text-xl'>Aprender</text>
                    </button>

                    <button
                        onClick={() => setIsLesson(false)}
                        class={`flex transition-all items-center font-font2 space-x-5 py-3 px-3 rounded-full hover:bg-[#f1ce4d] ${!isLesson ? "bg-[#fcc502]" : ""}`}>
                        <BsBookHalf size='1.5rem' color='#03296b' />
                        <text class='text-xl'>Lição</text>
                    </button>

                </div>

                <div class='flex space-x-2'>
                    <button class='p-3 bg-[#03296b] font-font1 text text-white rounded-lg ' onClick={RedirectLogin}>Log out</button>
                    <button class='p-3 bg-[#03296b]  font-font1 text text-white rounded-lg ' onClick={RedirectPayment}>Cobrança</button>
                </div>
            </div>

            <div class='w-fit md:hidden flex py-3 z-50' onClick={HandleSideBar}>
                <BiMenu size='2.5rem' />
            </div>


            <div class='md:w-[80%] w-full h-full md:p-10 p-4 flex flex-col justify-between items-center space-y-2'>
                <div class='lg:w-[65%] md:w-[80%] w-full flex flex-col overflow-y-scroll chat' id="chat">
                    {!isLesson &&
                        TotalMessageChat.map((obj) =>
                            obj.role === 'user' ? (
                                <UserChat content={obj.content} />
                            ) : obj.role === 'assistant' ? (
                                <BotChat content={obj.content} />
                            ) : null
                        )
                    }
                    {isLesson &&
                        TotalMessageLesson.map((obj) =>
                            obj.role === 'user' ? (
                                <UserChat content={obj.content} />
                            ) : obj.role === 'assistant' ? (
                                <BotChat content={obj.content} />
                            ) : null
                        )
                    }

                    <div className="" id="ScrollToMe"></div>

                </div>
                <div class='lg:w-[65%] md:w-[80%] w-full flex flex-col border-[2px] rounded-lg '>
                    <input id="input" class='w-full rounded-lg py-3 px-3 outline-none text-base font-font2' placeholder='Ask or search anything' onChange={HandleInput} value={Input}></input>
                    <form onSubmit={(e) => { e.preventDefault(); StartChat() }} class='w-full px-2 py-2 bg-gray-100 flex justify-between'>
                        <button class='py-2 px-2 flex items-center hover:bg-[#fcc502] rounded-lg'>
                            <RiVoiceprintFill size='1.2rem' />
                            <text class='sm:text-sm text-xs ml-3 font-font2'>Reconhecimento de voz</text>
                        </button>

                        <button disabled={Input.length <= 0 || Gen} type="submit" onClick={StartChat} class={Input.length > 0 && !Gen ? 'flex p-2 justify-center items-center border-[2px] bg-[#fcc502] rounded-full' : 'flex p-2 justify-center items-center border-[2px] bg-[#fcc502]/10 rounded-full'}>
                            <BiSolidSend size='1.2rem' color='white' />
                        </button>

                    </form>
                </div>
            </div>
        </div >
    )
}

export default Dashboard