import React, { useState, useEffect } from 'react'
import SinglePrice from './SinglePrice'
import PremiumPlan from './PremiumPlan'
import StandardPlan from './StandardPlan'
import axios from 'axios'
import { checkIfLogin } from '../supa'
function AuthPay() {

    const [standardPaymentLink, setStandardPaymentLink] = useState('');
    const [premiumPaymentLink, setPremiumPaymentLink] = useState('');
    const [loading, setLoading] = useState(false)
    const [dashboardLink, setDashboardLink] = useState("/");
    useEffect((goto = "/login") => {
        if (!checkIfLogin()) {
            window.location.href = goto;
        }
        else {
            getAll();
        }

    }, []);

    const getAll = async () => {
        setLoading(true);
        await getDashboard();

        await getPayStandard();
        await getPayPremium();

        setLoading(false);
    };


    function RedirectDashboard() {
        window.location.replace('/dashboard')
    }

    const getPayStandard = async () => {
        const jwt = `${checkIfLogin()}`;
        await axios
            .post(
                `${process.env.REACT_APP_BACKEND}/stripe/pay`,
                {
                    price: `${process.env.REACT_APP_PLAN1}`,
                    success_url: `${window.location.origin.toString()}/dashboard/`,
                    cancel_url: `${window.location.origin.toString()}/payment/`,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            .then(function (response) {
                setStandardPaymentLink(response.data);
            })
            .catch(function (error) {
                console.log(`Error : ${error}}`);
            });
    };

    const getPayPremium = async () => {
        const jwt = `${checkIfLogin()}`;
        await axios
            .post(
                `${process.env.REACT_APP_BACKEND}/stripe/pay`,
                {
                    price: `${process.env.REACT_APP_PLAN2}`,
                    success_url: `${window.location.origin.toString()}/dashboard/`,
                    cancel_url: `${window.location.origin.toString()}/payment/`,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            .then(function (response) {
                setPremiumPaymentLink(response.data);
            })
            .catch(function (error) {
                console.log(`Error : ${error}}`);
            });
    };

    const getDashboard = async () => {
        const jwt = `${checkIfLogin()}`;
        await axios
            .get(`${process.env.REACT_APP_BACKEND}/stripe/getDashboard/`, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            })
            .then(function (response) {
                setDashboardLink(response.data);
            })
            .catch(function (error) {
                console.log(`Error : ${error}}`);
            });
    };

    function premiumButton() {
        setLoading(true)
        window.location.href = `${premiumPaymentLink}`
        setLoading(false)

    }
    function standardButton() {
        setLoading(true)

        window.location.href = `${standardPaymentLink}`
        setLoading(false)

    }
    return (
        <div id='Prices' class='min-h-screen'>
            {loading && (<div className="flex items-center justify-center h-screen transition-all ">
                <div className="relative">
                    <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
                    <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
                    </div>
                </div>
            </div>)}
            <div class='w-full min-h-screen flex flex-col sm:px-16 px-8 pb-4 pt-24 justify-between space-y-14 items-center'>
                {/* <text class='text-5xl font-font1 font-bold'>Planos e Preços</text> */}
                <div className="flex justify-stretch items-center space-x-5">
                    <button class='p-3 bg-[#03296b] font-font1 text text-white rounded-lg ' onClick={() => {
                        setLoading(true)
                        window.location.replace('/dashboard')
                        setLoading(false)

                    }}>Painel</button>
                    <button class='p-3 bg-[#03296b]  font-font1 text text-white rounded-lg ' onClick={() => {
                        setLoading(true)
                        window.location.href = `${dashboardLink}`
                        setLoading(false)

                    }}>Cobrança Painel</button>

                </div>


                <div class='w-full min-h-full flex flex-row flex-wrap justify-center items-center'>
                    <SinglePrice button={RedirectDashboard} />
                    <PremiumPlan button={premiumButton} check={1} />
                    <StandardPlan button={standardButton} />
                </div>
            </div>
        </div>
    )
}

export default AuthPay