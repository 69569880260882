import React from 'react'
import { BsCheckLg } from 'react-icons/bs'

function PremiumPlan(props) {

    return (
        <div class={props.check == 1 ? 'py-5 w-[50vh] h-[70vh] border-[4px] border-[#fcc502] rounded-lg shadow-xl mr-5 mb-5' : 'w-[50vh] h-[70vh] border-[2px]  rounded-lg shadow-xl py-5 mr-5 mb-5'}>
            <div class='w-full h-full flex flex-col justify-between'>
                <div class={props.check == 1 ? 'w-full flex flex-col justify-center items-center space-y-2 border-b-[4px] border-[#fcc502] pb-2' : 'w-full flex flex-col justify-center items-center space-y-2 border-b-[2px] pb-2'}>
                    <text class='text-[3vh] font-font2 text-[#03296b]'>Prêmio</text>
                    <div class='flex flex-col items-center'>
                        <text class='text-6xl font-font1 font-bold text-[#03296b]'>€30</text>
                        <text class='text-black/50'>por mês</text>
                    </div>
                </div>
                <div class='w-full h-full flex flex-col justify-between px-5 pt-5'>
                    <div class='w-full flex-col flex space-y-2'>
                        <text class='flex items-center text-lg font-font2 '>
                            <BsCheckLg size='1.3rem' class='mr-2' color='#5FD068' />
                            Aulas Ilimitadas
                        </text>
                        <text class='flex items-center text-lg font-font2'>
                            <BsCheckLg size='1.3rem' class='mr-2' color='#5FD068' />
                            Bate-papos ilimitados
                        </text>
                        <text class='flex items-center text-lg font-font2'>
                            <BsCheckLg size='1.3rem' class='mr-2' color='#5FD068' />
                            Acesso a Kasoma - AI

                        </text>
                    </div>
                    <button onClick={props.button} class={props.check != 1 ? 'w-full py-3 rounded-lg bg-white border-[2px] border-[#03296b] text-[#03296b] font-font1 font-bold text-lg' : 'w-full py-3 rounded-lg text-white bg-[#03296b] font-font1 font-bold text-lg'}>Pague agora
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PremiumPlan